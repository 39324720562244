<!-- Tooltip 컴포넌트 -->
<template>
  <div class="">
    <div @click="showClickTooltip" @mouseover="showHoverTooltip" @mouseleave="hideTooltip">
      <div class="pl-2 c-pointer no-right-click">
        <slot></slot>
      </div>
      <div v-if="visible" :style="[tooltipStyle, tool]" ref="contentsDiv">
        <div id="contents" v-html="text"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    position: {
      type: String,
      default: 'right'
    },
    text: {
      type: String,
      default: ''
    },
    hoverDelay: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      visible: false, // 툴팁 표시 여부
      tooltipStyle: { top: '0px', left: '0px' }, // 툴팁 위치 설정
      tool: {
        position: 'absolute',
        backgroundColor: '#333',
        color: '#fff',
        padding: '5px 10px',
        borderRadius: '4px',
        fontSize: '12px',
        zIndex: '1000',
        width: 'max-content'
      },
      contentHeight: 0,
      contentWidth: 0,

      tooltipTimeout: null,  // 타이머 ID 저장
    };
  },
  mounted() {
  },
  methods: {
    showClickTooltip(event) {
      // const target = event.target.getBoundingClientRect();
      this.visible = true;

      // div 표시 후 툴팁 포지션 스타일 셋팅
      this.$nextTick(() => {
        if (this.visible && this.$refs.contentsDiv) {
          this.contentHeight = this.$refs.contentsDiv.clientHeight;
          this.contentWidth = this.$refs.contentsDiv.clientWidth;
        }
        // console.log("this.contentHeight", this.contentHeight);
        // console.log("this.contentWidth", this.contentWidth);
        this.positionTooltip(event);
      });
    },

    showHoverTooltip(event) {
      const delayTime = this.hoverDelay;
      this.tooltipTimeout = setTimeout(() => {
        if (this.tooltipTimeout >= 100) {
          this.visible = true;
        }
      }, delayTime);

      // div 표시 후 툴팁 포지션 스타일 셋팅
      this.$nextTick(() => {
        if (this.visible && this.$refs.contentsDiv) {
          this.contentHeight = this.$refs.contentsDiv.clientHeight;
          this.contentWidth = this.$refs.contentsDiv.clientWidth;
        }
        this.positionTooltip(event);
      });
    },

    hideTooltip() {
      this.visible = false;
      clearTimeout(this.tooltipTimeout);
      this.tooltipTimeout = 0;
    },

    // 툴팁 위치 계산
    positionTooltip(event) {
      const tooltipPositions = {
        top: {
          top: `${event.layerY - (this.contentHeight + 15)}px`,
          left: `${event.layerX}px`,
          transform: 'translateX(-50%)'
        },
        right: {
          top: `${event.layerY}px`,
          left: `${event.layerX + 20}px`,
          transform: 'translateY(-50%)'
        },
        bottom: {
          top: `${event.layerY + 20}px`,
          left: `${event.layerX}px`,
          transform: 'translateX(-50%)'
        },
        left: {
          top: `${event.layerY}px`,
          left: `${event.layerX - (this.contentWidth + 15)}px`,
          transform: 'translateY(-50%)'
        }
      };

      // position에 따른 스타일 설정
      this.tooltipStyle = tooltipPositions[this.position] || tooltipPositions.right;
    }
  }
};
</script>

<style scoped>
.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
}
</style>
