import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

import adminStore from './modules/adminStore';
import userStore from './modules/userStore';

const store = new Vuex.Store({
  modules: {
    adminStore,
    userStore,
  },
});

export default store;